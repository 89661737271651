import type { Context } from "@marko/run";

export type PageType =
  | "Nearby-Airports"
  | "A-to-B"
  | "Homepage"
  | "Direct-Flights"
  | "Transit-Near"
  | "Retarget";

export function getPageTypeFromRoute(route: Context["route"]): PageType {
  switch (route) {
    case "/":
    case "/:lang":
      return "Homepage";
    case "/s/:origin/:destination":
    case "/:lang/s/:origin/:destination":
      return "A-to-B";
    case "/s/Nearby-Airports/:destination":
    case "/:lang/s/Nearby-Airports/:destination":
      return "Nearby-Airports";
    case "/s/Direct-Flights/:destination":
    case "/:lang/s/Direct-Flights/:destination":
      return "Direct-Flights";
    case "/s/Transit-Near/:destination":
    case "/:lang/s/Transit-Near/:destination":
      return "Transit-Near";
    case "/campaign/retarget-a2b/:origin/:destination":
    case "/:lang/campaign/retarget-a2b/:origin/:destination":
    case "/campaign/retarget-destination/:destination":
    case "/:lang/campaign/retarget-destination/:destination":
      return "Retarget";
  }
}
