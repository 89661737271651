import type { FeatureFilter } from "./filter/FeatureFilter";
import { and } from "./filter/and";
import { isMobile } from "./filter/isMobile";
import { isCookie } from "./filter/isCookie";
import { isPageLanguage } from "./filter/isPageLanguage";
import { isPageType } from "./filter/isPageType";
import { isProductionLikeDeploy } from "./filter/isProductionLikeDeploy";
import { isDevDeploy } from "./filter/isDevDeploy";
import { isDesktop } from "./filter/isDesktop";
import { isPreviewDeploy } from "./filter/isPreviewDeploy";

// FeatureConfigValues is where you define the possible values for your Feature.
// Features can be either boolean or strings, using strings is useful for when
// you want to have multiple values for a feature or when those values are
// mutually exclusive.
export const FeatureConfigValues = {
  LogAnalyticsEventsToConsole: [false, true],
  PopunderOptOut: ["Inline", "Global", "Disabled"],
  ThrowTestError: ["Middleware", "PageRender", "Fetch", "Client", "None"],
  ShowDebug: [false, true],
  LanguageCode: [
    "da",
    "de",
    "en",
    "es",
    "fr",
    "it",
    "ja",
    "ko",
    "nl",
    "no",
    "pl",
    "pt",
    "ru",
    "sv",
    "th",
    "zh",
    "ro",
    "ms",
    "cs",
    "hu",
    "el",
    "fi",
    "sk",
    "hr",
  ],
  ForceFallbackImages: [false, true],
  FillAds: ["default", "never", "alwaysWithTestAds"],
  TripPageAdsStickyFooter: [false, true],
  TripPageAdsInlineMobile: [false, true],
  TripPageAdsLeaderboardDesktop: [false, true],
  TripPageAdsInlineDesktop: [false, true],
  ParseApiResponse: [false, true],
  Sentry: [false, true],
  AnalyticsPostStrategy: ["immediate", "idle"],
  Marker: [false, true],
  MockConsentStatus: ["given", "revoked", "unknown", "undefined"],
  A2BPage: [false, true],
} as const satisfies {
  // Features are either boolean features, or string features, never both.
  [key: string]: readonly string[] | readonly boolean[];
};

// FeatureConfigFilter is how you define the filters that are applied to
// your Feature. Filters are applied in-order, from left to right and must
// contain a fallback option at the end that is static.
//
// For example, given a request from a desktop device, evaluating
// [{ Inline: IsMobile() }, "Global"]
//
// 1. if (IsMobile) "Inline"
// 2. fallback to "Global"
//
export const FeatureConfigFilter: {
  [F in FeatureName]: FeatureFilterSet<F, FeatureValue<F>>;
} = {
  LogAnalyticsEventsToConsole: [import.meta.env.DEV],
  FillAds: ["default"],
  Sentry: [isProductionLikeDeploy(), false],
  AnalyticsPostStrategy: ["immediate"],
  PopunderOptOut: ["Inline"],
  ThrowTestError: ["None"],
  ShowDebug: [import.meta.env.DEV],
  LanguageCode: [
    {
      en: isCookie("language", "en"),
      de: isCookie("language", "de"),
      es: isCookie("language", "es"),
      fr: isCookie("language", "fr"),
      it: isCookie("language", "it"),
      pl: isCookie("language", "pl"),
      pt: isCookie("language", "pt"),
      ru: isCookie("language", "ru"),
      da: isCookie("language", "da"),
      no: isCookie("language", "no"),
      nl: isCookie("language", "nl"),
      sv: isCookie("language", "sv"),
      ja: isCookie("language", "ja"),
      ko: isCookie("language", "ko"),
      th: isCookie("language", "th"),
      zh: isCookie("language", "zh"),
      ro: isCookie("language", "ro"),
      ms: isCookie("language", "ms"),
      cs: isCookie("language", "cs"),
      hu: isCookie("language", "hu"),
      el: isCookie("language", "el"),
      fi: isCookie("language", "fi"),
      sk: isCookie("language", "sk"),
      hr: isCookie("language", "hr"),
    },
    {
      en: isPageLanguage("en"),
      de: isPageLanguage("de"),
      es: isPageLanguage("es"),
      fr: isPageLanguage("fr"),
      it: isPageLanguage("it"),
      pl: isPageLanguage("pl"),
      pt: isPageLanguage("pt"),
      ru: isPageLanguage("ru"),
      da: isPageLanguage("da"),
      no: isPageLanguage("no"),
      nl: isPageLanguage("nl"),
      sv: isPageLanguage("sv"),
      ja: isPageLanguage("ja"),
      ko: isPageLanguage("ko"),
      th: isPageLanguage("th"),
      zh: isPageLanguage("zh"),
      ro: isPageLanguage("ro"),
      ms: isPageLanguage("ms"),
      cs: isPageLanguage("cs"),
      hu: isPageLanguage("hu"),
      el: isPageLanguage("el"),
      fi: isPageLanguage("fi"),
      sk: isPageLanguage("sk"),
      hr: isPageLanguage("hr"),
    },
    "en",
  ],
  ForceFallbackImages: [false],
  TripPageAdsStickyFooter: [and(isPageType("A-to-B"), isMobile()), false],
  TripPageAdsInlineMobile: [and(isPageType("A-to-B"), isMobile()), false],
  TripPageAdsLeaderboardDesktop: [
    and(isPageType("A-to-B"), isDesktop()),
    false,
  ],
  TripPageAdsInlineDesktop: [and(isPageType("A-to-B"), isDesktop()), false],
  ParseApiResponse: [import.meta.env.DEV],
  Marker: [isDevDeploy(), false],
  MockConsentStatus: [
    import.meta.env.DEV ? "given" : undefined,
    isPreviewDeploy() && "given",
    "undefined",
  ],
  A2BPage: [isPageType("A-to-B"), false],
};

export type FeatureFilterSet<
  F extends FeatureName,
  V extends FeatureValue<F>,
> = [...FeatureFilter<F, V>[], V];

export type FeatureName = keyof typeof FeatureConfigValues;

export type FeatureValue<F extends FeatureName> =
  (typeof FeatureConfigValues)[F][number];

export type FeatureConfig = {
  [F in FeatureName]: FeatureValue<F>;
};
