import {
  type PageType,
  getPageTypeFromRoute,
} from "src/url/getPageTypeFromRoute";
import type { FeatureFilterFunc } from "./FeatureFilter";
import type { Context } from "@marko/run";

export function isPageType(pageType: PageType): FeatureFilterFunc {
  return (ctx: Context) => {
    return getPageTypeFromRoute(ctx.route) === pageType ? true : undefined;
  };
}
