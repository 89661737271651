import type { LanguageCode } from "src/utils/language";
import { buildHomepagePathname } from "./buildHomepagePathname";
import { buildLandingPagePathname } from "./buildLandingPagePathname";
import type { Context } from "@marko/run";
import { getPageTypeFromRoute } from "./getPageTypeFromRoute";

export function buildPathnameFromRoute(
  route: Context["route"],
  languageCode: LanguageCode,
  params: Context["params"],
): string {
  const pageType = getPageTypeFromRoute(route);
  switch (pageType) {
    case "Homepage":
      return buildHomepagePathname(languageCode);
    case "A-to-B":
      // TypeScript can't narrow the type of `context.params` here, so we have to
      // cast it.
      const tripParams = params as {
        origin: string;
        destination: string;
      };
      return buildLandingPagePathname(
        languageCode,
        tripParams.origin,
        tripParams.destination,
      );
    case "Nearby-Airports":
      // TypeScript can't narrow the type of `context.params` here, so we have to
      // cast it.
      const nearbyParams = params as { destination: string };
      return buildLandingPagePathname(
        languageCode,
        "Nearby-Airports",
        nearbyParams.destination,
      );
    case "Direct-Flights":
    case "Transit-Near":
    case "Retarget": {
      // Put all params into the route
      const pathParams = { lang: languageCode, ...params };
      let pathName: string = route.startsWith("/:lang")
        ? route
        : `/:lang${route}`;
      for (const key in pathParams) {
        pathName = pathName.replace(
          `:${key}`,
          encodeURIComponent(pathParams[key as keyof typeof pathParams]),
        );
      }

      // Remove any starting /en/ - english is default.
      pathName = pathName.replace(/^\/en\//, "/");

      return pathName;
    }
  }
}
